import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from "../hooks";
import { ApplicationModal, setModalState, setOpenModal } from "./reducer";
export var useBlockNumber = () => {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  return useAppSelector(state => state.application.blockNumber[chainId]);
};
export var useMainnetBlockNumber = () => {
  return useAppSelector(state => state.application.blockNumber[1]);
};
export var useModalOpened = modal => {
  var modalOpened = useAppSelector(state => state.application.openModal);
  return modalOpened.includes(modal);
};
export var useOneOfModalOpened = modals => {
  var modalOpened = useAppSelector(state => state.application.openModal);
  return modalOpened.some(modal => modals.includes(modal));
};
export var useModalToggle = (modal, opt) => {
  var _ref = opt || {
    stack: false,
    modalState: null
  },
      stack = _ref.stack,
      modalState = _ref.modalState;

  var dispatch = useAppDispatch();
  var opened = useModalOpened(modal);
  var modalOpened = useAppSelector(state => state.application.openModal);
  return useCallback(() => {
    dispatch(setOpenModal(opened ? modalOpened.filter(m => m !== modal) : stack ? [...modalOpened, modal] : [modal]));
    dispatch(setModalState({
      modal,
      state: modalState
    }));
  }, [dispatch, modal, opened, modalState, stack, modalOpened]);
};
export var useModalSwitch = modal => {
  var dispatch = useAppDispatch();
  var opened = useModalOpened(modal);
  var modalOpened = useAppSelector(state => state.application.openModal);
  return useCallback((open, opt) => {
    // opened
    if (open === opened) return;

    var _ref2 = opt || {
      stack: false,
      modalState: null
    },
        stack = _ref2.stack,
        modalState = _ref2.modalState;

    if (stack) {
      dispatch(setOpenModal(open ? [...modalOpened, modal] : modalOpened.filter(m => m !== modal)));
    } else {
      dispatch(setOpenModal(open ? [modal] : []));
    }

    dispatch(setModalState({
      modal,
      state: modalState
    }));
  }, [dispatch, modal, opened]);
};
export var useModalState = modal => {
  return useAppSelector(state => {
    var _state$application$mo;

    return (_state$application$mo = state.application.modalState[modal]) !== null && _state$application$mo !== void 0 ? _state$application$mo : {};
  });
};
export var useApprovalModalStateDispatch = () => {
  var dispatch = useAppDispatch();
  return useCallback(_ref3 => {
    var progress = _ref3.progress,
        hash = _ref3.hash;
    dispatch(setModalState({
      modal: ApplicationModal.APPROVAL_STATUS,
      state: {
        progress,
        hash
      }
    }));
  }, [dispatch]);
};
export var useRefreshToken = () => {
  return useAppSelector(state => state.application.refreshToken);
};
export var useFeeData = () => {
  var _useWeb3React2 = useWeb3React(),
      chainId = _useWeb3React2.chainId;

  return useAppSelector(state => state.application.feeData[chainId]);
};