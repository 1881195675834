import React from "react";
var __jsx = React.createElement;
import "../src/i18n";
import ApplicationUpdater from "../src/state/application/updater";
import Metas from "../src/components/Metas";
import InfoModal from "../src/components/InfoModal";
import MulticallUpdater from "../src/state/multicall/updater";
import DexUpdater from "../src/state/dex/updater";
import { ApprovalModal } from "../src/components/ApprovalModal";
import { AllowanceListModal } from "../src/components/InfoModal/AllowanceList";
import { TransactionUpdater } from "../src/state/transactions/updater";
import { UserUpdater } from "../src/state/user/updater";
import { globalTxErrorHandler } from "../src/utils/handleTxError";
import { TokenPriceUpdater } from "../src/hooks/useTokenPrice";
import { GlobalStyle } from "../src/theme/GlobalStyle";
import { ToolModal } from "../src/components/ToolModal";
import "../src/sentry";
import "../src/components/Message/Message.css";
import "../src/components/Tooltip/styled.css";
import GlobalProvider from "../src/components/GlobalProvider";
import WalletModal from "../src/components/WalletModal";
globalTxErrorHandler();

var Updaters = () => {
  return __jsx(React.Fragment, null, __jsx(ApplicationUpdater, null), __jsx(MulticallUpdater, null), __jsx(TransactionUpdater, null), __jsx(DexUpdater, null), __jsx(UserUpdater, null), __jsx(TokenPriceUpdater, null));
};

var Pops = () => {
  return __jsx(React.Fragment, null, __jsx(WalletModal, null), __jsx(InfoModal, null), __jsx(AllowanceListModal, null), __jsx(ApprovalModal, null), __jsx(ToolModal, null));
};

var Tokenlon = _ref => {
  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  var _pageProps$openGraphD = pageProps.openGraphData,
      openGraphData = _pageProps$openGraphD === void 0 ? [] : _pageProps$openGraphD;
  return __jsx(React.Fragment, null, __jsx(Metas, {
    openGraphData: openGraphData
  }), __jsx(GlobalStyle, null), __jsx(GlobalProvider, null, __jsx(Updaters, null), __jsx(Component, pageProps), __jsx(Pops, null)));
};

export default Tokenlon;