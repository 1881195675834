import _extends from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import styled from 'styled-components';
import Close from "../../assets/images/close.png";
import CloseWhite from "./assets/modal-close-white.svg";
import { MediaBreakPointUp } from "../../theme";
import { lockBody } from "../../utils/lockBody";
import Portal from "../Portal";
export var Modal = _ref => {
  var children = _ref.children;
  return children;
};
var dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500
    }
  },
  exit: {
    y: '100vh',
    opacity: 0
  }
};
export var Icon = styled.i.withConfig({
  displayName: "Icon",
  componentId: "sc-ft5hl2-0"
})(["width:24px;height:24px;cursor:pointer;z-index:2;transition:background-color ease-in-out 0.2s;border-radius:8px;background-color:transparent;cursor:pointer;background-position:center;&::after{content:'';width:24px;height:24px;display:inline-block;background-image:url(", ");background-repeat:no-repeat;background-size:contain;background-position:center;}&:hover{background-color:", ";}"], _ref2 => {
  var url = _ref2.url;
  return url;
}, _ref3 => {
  var light = _ref3.light;
  return light ? 'transparent' : '#f5f7fb';
});
var DefaultHeader = styled.div.withConfig({
  displayName: "DefaultHeader",
  componentId: "sc-ft5hl2-1"
})(["display:flex;align-items:center;justify-content:space-between;box-shadow:inset 0px -1px 0px #eaecf6;background-color:#fff;margin:-1rem;margin-bottom:2rem;border-radius:1rem 1rem 0 0;padding:12px 10px;.content{display:flex;align-items:center;justify-content:center;flex:1;margin-left:-30px;margin-right:-30px;font-weight:600;font-size:15px;line-height:24px;padding:0 30px;}.left,.right{display:flex;align-items:center;min-width:24px;}&.addCustomToken{margin-bottom:10px;}"]);

var Header = _ref4 => {
  var children = _ref4.children,
      left = _ref4.left,
      right = _ref4.right,
      title = _ref4.title,
      onDismiss = _ref4.onDismiss,
      headerClassName = _ref4.headerClassName;
  var content = title || children;
  if (!content && !left && !right) return null;
  left = left || __jsx(Icon, {
    url: Close.src,
    onClick: onDismiss
  });
  return __jsx(DefaultHeader, {
    className: headerClassName
  }, __jsx("div", {
    className: "left"
  }, left), __jsx("div", {
    className: "content"
  }, content), __jsx("div", {
    className: "right"
  }, right));
};

Modal.Header = Header;

var ModalClose = _ref5 => {
  var onDismiss = _ref5.onDismiss,
      light = _ref5.light,
      className = _ref5.className;
  return __jsx(Icon, {
    className: className,
    light: light,
    url: light ? CloseWhite : Close,
    onClick: onDismiss
  });
};

Modal.Close = ModalClose;
var Footer = styled.div.withConfig({
  displayName: "Footer",
  componentId: "sc-ft5hl2-2"
})([""]);
Modal.Footer = Footer;
var DefaultContent = styled(motion.div).withConfig({
  displayName: "DefaultContent",
  componentId: "sc-ft5hl2-3"
})(["z-index:2;background-color:#fff;width:85vw;padding:1rem;border-radius:1rem;max-width:435px;@media screen and (max-width:", "){width:calc(100% - 20px);margin:0 10px;box-sizing:border-box;}&.lon-reward{padding:10px;background:linear-gradient(180deg,#e5e0ff 0%,#ffffff 66.09%);max-width:355px;}&.fee-intro{margin:15px 0;padding:24px 24px 20px;background:linear-gradient(180deg,#e5e0ff 0%,#ffffff 31.48%);max-width:400px;overflow:auto;}&.pay-with-tokens-intro{max-width:355px;padding:45px 24px 20px;z-index:3;overflow:auto;}&.permit2-intro{max-width:355px;padding:45px 24px 20px;z-index:3;overflow:auto;}&.mini-set-pay-mode{max-width:355px;padding:45px 24px 20px;background:#f5f7fb;}&.lo-trade-fee-intro{padding:45px 24px 20px;max-width:355px;}&.lo-preview-warning{padding:45px 24px 20px;max-width:355px;}&.new-modal-base-content{padding:32px 20px 20px;max-width:368px;}&.smart-routing-details{background:linear-gradient(180deg,#e9e4ff 0%,#fff 23.67%);}&.spotswap-onboarding{padding:10px;background:linear-gradient(180deg,#e9e4ff 0%,#fff 23.67%);max-width:368px;}"], MediaBreakPointUp.sm);

var Content = _ref6 => {
  var children = _ref6.children,
      className = _ref6.className,
      _ref6$animate = _ref6.animate,
      animate = _ref6$animate === void 0 ? true : _ref6$animate;
  var animation = animate ? dropIn : {};
  return __jsx(DefaultContent, {
    onClick: e => e.stopPropagation(),
    className: className,
    variants: animation,
    initial: "hidden",
    animate: "visible",
    exit: "exit"
  }, children);
};

Modal.Content = Content;
var DefaultOverlay = styled(motion.div).withConfig({
  displayName: "DefaultOverlay",
  componentId: "sc-ft5hl2-4"
})(["background:radial-gradient( rgba(0,0,0,0.4),rgba(0,0,0,0.5),rgba(0,0,0,0.6) );position:fixed;top:0;left:0;right:0;bottom:0;display:flex;justify-content:center;align-items:center;flex-direction:column;z-index:101;"]);

var Overlay = _ref7 => {
  var children = _ref7.children,
      className = _ref7.className,
      onDismiss = _ref7.onDismiss,
      _ref7$animate = _ref7.animate,
      animate = _ref7$animate === void 0 ? true : _ref7$animate;
  var animation = animate ? {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1
    },
    exit: {
      opacity: 0
    }
  } : {};

  var onEsc = e => {
    if (e.key === 'Escape') {
      onDismiss && onDismiss();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onEsc);
    return () => {
      document.removeEventListener('keydown', onEsc);
    };
  }, []);
  return __jsx(DefaultOverlay, _extends({
    onClick: onDismiss,
    className: className
  }, animation), children);
};

Modal.Overlay = Overlay;

var DefaultModal = _ref8 => {
  var isOpen = _ref8.isOpen,
      onDismiss = _ref8.onDismiss,
      children = _ref8.children,
      _ref8$title = _ref8.title,
      title = _ref8$title === void 0 ? '' : _ref8$title,
      left = _ref8.left,
      _ref8$animate = _ref8.animate,
      animate = _ref8$animate === void 0 ? true : _ref8$animate,
      contentClassName = _ref8.contentClassName,
      headerClassName = _ref8.headerClassName;
  useEffect(() => {
    lockBody(isOpen);
  }, [isOpen]);
  return __jsx(Portal, null, __jsx(AnimatePresence, null, isOpen && __jsx(Modal.Overlay, {
    onDismiss: onDismiss,
    animate: animate
  }, __jsx(Modal.Content, {
    animate: animate,
    className: contentClassName
  }, __jsx(Modal.Header, {
    title: title,
    left: left,
    onDismiss: onDismiss,
    headerClassName: headerClassName
  }), children))));
};

export var SeamlessModal = _ref9 => {
  var isOpen = _ref9.isOpen,
      onDismiss = _ref9.onDismiss,
      children = _ref9.children,
      _ref9$title = _ref9.title,
      title = _ref9$title === void 0 ? '' : _ref9$title,
      _ref9$animate = _ref9.animate,
      animate = _ref9$animate === void 0 ? true : _ref9$animate,
      contentClassName = _ref9.contentClassName;
  return __jsx(Portal, null, __jsx(AnimatePresence, null, isOpen && __jsx(Modal.Content, {
    animate: animate,
    className: contentClassName
  }, __jsx(Modal.Header, {
    title: title,
    onDismiss: onDismiss
  }), children)));
};
export default DefaultModal;