import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { WETH as WETH_ADDRESSES } from "../constants/addresses";
import { WETHABI } from "../constants/abi";
import { useContract } from "./useContract";
import { message } from "../components/Message";
import { useCallback } from 'react';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { ArbitrumOne, ArbitrumRinkeby, Mainnet, Sepolia } from "../model/chain/ethereum";
import { useConnectType } from "../state/user/hooks";
import { WalletType } from "../constants/walletType";
import { useFeeData } from "../state/application/hooks";
import { useWeb3React } from '@web3-react/core';
var OVERRIDES = {
  // @notice: imToken App(2.11.2) arbitrum 网络不支持传入 type=2, 会导致交易失败：maxFee 会被设置为0
  [ArbitrumOne.chainId]: {
    type: 0,
    gasLimit: 210000
  },
  [ArbitrumRinkeby.chainId]: {
    type: 0,
    gasLimit: 60000
  },
  [Mainnet.chainId]: {
    type: 2,
    gasLimit: 50000
  },
  [Sepolia.chainId]: {
    type: 2,
    gasLimit: 50000
  }
};
export var useWETH = () => {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  var WETH_ADDRESS = WETH_ADDRESSES[chainId];
  return useContract(WETH_ADDRESS, WETHABI.abi);
};
export var useWETHDefaultOverrides = () => {
  var connectType = useConnectType();
  var feeData = useFeeData();

  var _useWeb3React2 = useWeb3React(),
      chainId = _useWeb3React2.chainId;

  var _ref = OVERRIDES[chainId] || {},
      type = _ref.type,
      gasLimit = _ref.gasLimit; // @notice: imToken App(2.11.2) wc 连接下不支持传入 type=2, 会导致交易一直处于 pending 中


  var isWalletConnect = [WalletType.IMTOKEN_WALLET_CONNECT, WalletType.METAMASK_WALLET_CONNECT, WalletType.WALLET_CONNECT].includes(connectType);
  var txType = isWalletConnect ? 0 : type;
  var gas = txType === 0 ? {
    gasPrice: feeData === null || feeData === void 0 ? void 0 : feeData.gasPrice
  } : {
    maxFeePerGas: feeData === null || feeData === void 0 ? void 0 : feeData.maxFeePerGas,
    maxPriorityFeePerGas: feeData === null || feeData === void 0 ? void 0 : feeData.maxPriorityFeePerGas
  };
  return _objectSpread({
    type: txType || 0,
    gasLimit: gasLimit || 210000
  }, gas);
};
export var useWETHDepositCallback = () => {
  var WETH = useWETH();
  var overrides = useWETHDefaultOverrides();
  var estimateGas = useCallback(function () {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ethers.BigNumber.from(1);

    if (WETH && ethers.BigNumber.isBigNumber(value)) {
      return WETH.estimateGas.deposit({
        value
      }).then(gas => ethers.BigNumber.from(gas.toString())).catch(error => {
        console.warn('failed to estimate WETH deposit', error);
        return ethers.BigNumber.from(overrides.gasLimit);
      });
    }

    return ethers.BigNumber.from(overrides.gasLimit);
  }, [WETH, overrides.gasLimit]);
  var depositCallback = useCallback(function (amount) {
    var estimateGasLimit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    if (!WETH) {
      message.error('Wrong Network!');
      return Promise.reject(0);
    }

    var gasLimit;

    try {
      gasLimit = estimateGasLimit;
      gasLimit = BigNumber.max(gasLimit.toString(), overrides.gasLimit).toString();
    } catch (error) {
      gasLimit = ethers.BigNumber.from(overrides.gasLimit);
    }

    console.log(_objectSpread(_objectSpread({}, overrides), {}, {
      gasLimit: ethers.BigNumber.from(gasLimit).mul(120).div(100).toString()
    }));
    return WETH.deposit(_objectSpread(_objectSpread({
      value: ethers.BigNumber.from(amount)
    }, overrides), {}, {
      gasLimit: ethers.BigNumber.from(gasLimit).mul(120).div(100).toString()
    }));
  }, [WETH]);
  return {
    deposit: depositCallback,
    estimateGas
  };
};
export var useWETHWithdrawCallback = () => {
  var WETH = useWETH();
  var overrides = useWETHDefaultOverrides();
  var estimateGas = useCallback(function () {
    var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ethers.BigNumber.from(1);

    if (WETH) {
      return WETH.estimateGas.withdraw(amount).then(gas => ethers.BigNumber.from(gas.toString())).catch(error => {
        console.warn('error estimate WETH withdraw', error);
        return ethers.BigNumber.from(overrides.gasLimit);
      });
    }

    return ethers.BigNumber.from(overrides.gasLimit);
  }, [WETH, overrides.gasLimit]);
  var withdrawCallback = useCallback(function (amount) {
    var estimateGasLimit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    if (!WETH) {
      message.error('Wrong Network!');
      return Promise.reject(0);
    }

    var gasLimit;

    try {
      gasLimit = estimateGasLimit;
      gasLimit = BigNumber.max(gasLimit.toString(), overrides.gasLimit).toString();
    } catch (error) {
      gasLimit = ethers.BigNumber.from(overrides.gasLimit);
    }

    console.log('deposit', _objectSpread(_objectSpread({}, overrides), {}, {
      gasLimit: ethers.BigNumber.from(gasLimit).mul(120).div(100)
    }));
    return WETH.withdraw(amount, _objectSpread(_objectSpread({}, overrides), {}, {
      gasLimit: ethers.BigNumber.from(gasLimit).mul(120).div(100)
    }));
  }, [WETH]);
  return {
    withdraw: withdrawCallback,
    estimateGas
  };
};