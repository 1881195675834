import { useEffect, useRef } from 'react';
import { useBlockNumber } from "../state/application/hooks";
export var FETCH_PER_BLOCK = 1;
export var useUpdaterByBlock = function useUpdaterByBlock(updater) {
  var blocksPerFetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  var blockNumber = useBlockNumber();
  var blocksPerFetchRef = useRef(0); // first run

  useEffect(() => {
    updater(blockNumber);
  }, []);
  useEffect(() => {
    if (blocksPerFetch === 0) return;

    if (!blocksPerFetchRef.current) {
      blocksPerFetchRef.current = blockNumber;
      return;
    }

    if (blockNumber - blocksPerFetchRef.current >= blocksPerFetch) {
      blocksPerFetchRef.current = blockNumber;
    }
  }, [blockNumber]);
  useEffect(() => {
    console.log('updater by blockNumber', blocksPerFetchRef.current);
    updater(blocksPerFetchRef.current);
  }, [blocksPerFetchRef.current, updater]);
};
export default useUpdaterByBlock;