import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useMemo } from 'react';
import { AddressZero } from '@ethersproject/constants';
import { useSpotSwapPair, useSpotSwapUserConfig } from "../state/dex/hooks";
export function useIsSendByRelayer() {
  var _useSpotSwapPair = useSpotSwapPair(),
      _useSpotSwapPair2 = _slicedToArray(_useSpotSwapPair, 1),
      inputToken = _useSpotSwapPair2[0];

  var _useSpotSwapUserConfi = useSpotSwapUserConfig(),
      sendByRelayer = _useSpotSwapUserConfi.sendByRelayer;

  var isSendByRelayer = useMemo(() => {
    var isTakerETH = (inputToken === null || inputToken === void 0 ? void 0 : inputToken.address) === AddressZero;
    return !isTakerETH && sendByRelayer;
  }, [inputToken === null || inputToken === void 0 ? void 0 : inputToken.address, sendByRelayer]);
  return isSendByRelayer;
}