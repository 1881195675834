import { captureException } from '@sentry/nextjs';
import { ErrorCode } from '@ethersproject/logger';
import i18n from "../i18n";
import { message } from "../components/Message";
import { isClient } from "./userAgent";
export class TxError extends Error {
  constructor(code) {
    var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    super(message);
    this.code = code;
    this.message = message;
  }

}
export var handleTxError = error => {
  var t = i18n.t;

  if (error instanceof Error) {
    var e = error;
    var errorCode = e.code;

    switch (errorCode) {
      case ErrorCode.UNKNOWN_ERROR:
      case ErrorCode.NOT_IMPLEMENTED:
      case ErrorCode.UNSUPPORTED_OPERATION:
      case ErrorCode.NETWORK_ERROR:
      case ErrorCode.SERVER_ERROR:
      case ErrorCode.TIMEOUT:
      case ErrorCode.BUFFER_OVERRUN:
      case ErrorCode.NUMERIC_FAULT:
      case ErrorCode.MISSING_NEW:
      case ErrorCode.INVALID_ARGUMENT:
      case ErrorCode.MISSING_ARGUMENT:
      case ErrorCode.UNEXPECTED_ARGUMENT:
      case ErrorCode.CALL_EXCEPTION:
      case ErrorCode.INSUFFICIENT_FUNDS:
      case ErrorCode.NONCE_EXPIRED:
      case ErrorCode.REPLACEMENT_UNDERPRICED:
      case ErrorCode.UNPREDICTABLE_GAS_LIMIT:
      case ErrorCode.TRANSACTION_REPLACED:
        captureException('Tx Error', {
          extra: {
            errorCode,
            error: e
          }
        });
        message.error(t("provider_error_code_".concat(errorCode)));
        break;

      default:
        message.error(t("provider_error_code_".concat(ErrorCode.UNKNOWN_ERROR)));
        console.error("unexpected error code: ".concat(errorCode), e);
        return;
    }
  } else if (typeof error === 'object' && error !== null && 'message' in error) {
    // catch WalletConnect imToken matchEthSendMsg error which won't be caught by error instanceof Error
    // https://github.com/consenlabs/token-v2/blob/85cd5eea1a9e7b72ce738fb806f4ad9c7e402d8f/src/global/TokenPay/helper.ts#L165-L172
    var _e = error;
    captureException('Tx Error', {
      extra: {
        error: _e.message
      }
    });
    message.error(_e.message);
  }

  if (typeof error === 'string') {
    captureException('Tx Error', {
      extra: {
        error
      }
    });
    message.error(error);
  }
};
export var globalTxErrorHandler = () => {
  if (isClient) {
    window.addEventListener('error', event => {
      if (event.error instanceof Error) {
        handleTxError(event.error);
      }
    });
  }
};