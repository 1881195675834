import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useModalOpened, useModalState, useModalToggle } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { Modal } from "../Modal";
import Portal from "../Portal";
import TokenLogo from "../TokenLogo";
import { ApproveStatusLabel } from "./ApproveStatusLabel";
import { useEffect } from 'react';
import { lockBody } from "../../utils/lockBody";
export var ApprovalModal = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var isOpen = useModalOpened(ApplicationModal.APPROVAL_STATUS);
  var toggleApprovalModal = useModalToggle(ApplicationModal.APPROVAL_STATUS);

  var _useModalState = useModalState(ApplicationModal.APPROVAL_STATUS),
      token = _useModalState.token,
      showLogo = _useModalState.showLogo,
      title = _useModalState.title,
      description1 = _useModalState.description1,
      description2 = _useModalState.description2;

  var onApprovalDismiss = () => {
    toggleApprovalModal();
  };

  useEffect(() => {
    lockBody(isOpen);
  }, [isOpen]);
  return __jsx(Portal, null, isOpen && __jsx(Modal.Overlay, {
    onDismiss: onApprovalDismiss
  }, __jsx(StyledApprovalContent, null, __jsx("div", {
    className: "token-info"
  }, __jsx(StyledIconClose, {
    light: true,
    onDismiss: onApprovalDismiss
  }), __jsx("h2", null, title), __jsx("p", null, description1), __jsx("p", null, description2), showLogo && __jsx("div", {
    className: "token-logo"
  }, __jsx(TokenLogo, {
    logo: token === null || token === void 0 ? void 0 : token.logo,
    symbol: token === null || token === void 0 ? void 0 : token.symbol,
    size: 50
  }), __jsx("span", {
    className: "logo-symbol"
  }, token === null || token === void 0 ? void 0 : token.symbol))), __jsx(ApproveStatusLabel, null))));
};
var StyledApprovalContent = styled(Modal.Content).withConfig({
  displayName: "StyledApprovalContent",
  componentId: "sc-1ed1f0r-0"
})(["display:flex;align-items:flex-start;flex-direction:column;background:rgb(38,44,75);width:360px;padding:0;.token-info{padding-left:25px;padding-top:30px;margin-bottom:20px;}h2{color:white;font-size:18px;font-weight:600;line-height:17px;margin-bottom:20px;}p{display:flex;align-items:center;margin-bottom:0px;color:#fff;font-size:12px;line-height:24px;color:var(--i-14,#9C9FB4);}.token-logo{display:flex;gap:8px;margin-top:20px;align-items:center;justify-content:flex-start;}span{&.logo-symbol{color:#fff;font-size:18px;font-style:normal;font-weight:600;line-height:24px;}}"]);
var StyledIconClose = styled(Modal.Close).withConfig({
  displayName: "StyledIconClose",
  componentId: "sc-1ed1f0r-1"
})(["position:absolute;right:18px;top:18px;height:12px;width:12px;cursor:pointer;&::after{content:'';width:12px;height:12px;}"]);