import React from "react";
var __jsx = React.createElement;
import { useNetworkOptions } from "../../../hooks";
import { ConnectLabel, StyledConnectOptions, StyledOption } from "./StyledOptions";
import Checked from "../../../assets/images/checked.svg";
import { ArbitrumOne, ArbitrumRinkeby, Mainnet, Sepolia } from "../../../model/chain/ethereum";
import { useTranslation } from 'react-i18next';
import { useSpotSwapRouter } from "../../../hooks";
export var NetworkConnectOptions = _ref => {
  var label = _ref.label,
      onSelect = _ref.onSelect,
      chainId = _ref.chainId;
  var networks = useNetworkOptions();

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var isSpotSwap = useSpotSwapRouter();
  var NetworkLabel = {
    [Mainnet.chainId]: t('network_label_spot'),
    [Sepolia.chainId]: t('network_label_spot'),
    [ArbitrumOne.chainId]: t('network_label_limit'),
    [ArbitrumRinkeby.chainId]: t('network_label_limit')
  };
  var ArbitrumChains = [ArbitrumOne.chainId, ArbitrumRinkeby.chainId];
  return __jsx(StyledConnectOptions, {
    className: "connect-options"
  }, label && __jsx(ConnectLabel, null, " ", label, " "), networks.map(chain => __jsx(StyledOption, {
    disabled: isSpotSwap && ArbitrumChains.includes(chain.chainId),
    key: chain.chainId,
    onClick: () => onSelect(chain.chainId)
  }, __jsx("img", {
    src: chain.logoUrl,
    alt: chain.chainName,
    className: "logo"
  }), chain.chainName, __jsx("span", {
    className: "connect-label"
  }, NetworkLabel[chain.chainId]), Number(chain.chainId) === Number(chainId) ? __jsx("img", {
    src: Checked,
    alt: "",
    className: "option-checked"
  }) : __jsx("span", {
    className: "option-unchecked"
  }))));
};