import React from "react";
var __jsx = React.createElement;
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ReactComponent as IconDeny } from "./assets/deny-slicing.svg";
import { ReactComponent as IconLoading } from "./assets/ellipse.svg";
import { ReactComponent as IconSuccess } from "./assets/success-checked.svg";
import { useApprovalModalStateDispatch, useModalOpened, useModalState, useModalToggle } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { useTransaction, useTransactionIsConfirmed } from "../../state/transactions/hooks";
import { BigNumber } from 'ethers';
export var ApprovalProgress;

(function (ApprovalProgress) {
  ApprovalProgress["APPROVING"] = "APPROVING";
  ApprovalProgress["APPROVE_REJECTED"] = "APPROVE_REJECTED";
  ApprovalProgress["APPROVE_SUCCESS"] = "APPROVE_SUCCESS";
  ApprovalProgress["CANCEL_APPROVING"] = "CANCEL_APPROVING";
  ApprovalProgress["CANCEL_REJECTED"] = "CANCEL_REJECTED";
  ApprovalProgress["CANCEL_SUCCESS"] = "CANCEL_SUCCESS";
})(ApprovalProgress || (ApprovalProgress = {}));

export var ApprovalProgressConfig = {
  [ApprovalProgress.APPROVING]: {
    i18n: 'approving_pls_sign',
    icon: IconLoading
  },
  [ApprovalProgress.APPROVE_REJECTED]: {
    i18n: 'rejected',
    icon: IconDeny
  },
  [ApprovalProgress.APPROVE_SUCCESS]: {
    i18n: 'approve_success',
    icon: IconSuccess
  },
  [ApprovalProgress.CANCEL_APPROVING]: {
    i18n: 'unApproving',
    icon: IconLoading
  },
  [ApprovalProgress.CANCEL_REJECTED]: {
    i18n: 'unApprove_rejected',
    icon: IconDeny
  },
  [ApprovalProgress.CANCEL_SUCCESS]: {
    i18n: 'unApprove_success',
    icon: IconSuccess
  }
};
export var ApproveStatusLabel = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var toggleApprovalModal = useModalToggle(ApplicationModal.APPROVAL_STATUS);

  var _useModalState = useModalState(ApplicationModal.APPROVAL_STATUS),
      progress = _useModalState.progress,
      hash = _useModalState.hash;

  var isOpen = useModalOpened(ApplicationModal.APPROVAL_STATUS);
  var updateApprovalState = useApprovalModalStateDispatch();
  var setting = ApprovalProgressConfig[progress];
  var txConfirmed = useTransactionIsConfirmed(hash);
  var tx = useTransaction(hash);

  var closeApprovalModal = () => {
    setTimeout(() => {
      if (isOpen) {
        toggleApprovalModal();
        updateApprovalState({
          progress: ApprovalProgress.APPROVING
        });
      }
    }, 2000);
  };

  var animationVariants = [ApprovalProgress.APPROVING, ApprovalProgress.CANCEL_APPROVING].some(i => i === progress) ? {
    animate: {
      rotate: [0, 360]
    },
    transition: {
      duration: 1,
      ease: 'linear',
      repeat: Infinity
    }
  } : {};
  useEffect(() => {
    if (txConfirmed && tx) {
      updateApprovalState({
        progress: BigNumber.from(tx.info.amountToApprove).isZero() ? ApprovalProgress.CANCEL_SUCCESS : ApprovalProgress.APPROVE_SUCCESS
      });
    }
  }, [txConfirmed, hash]);
  useEffect(() => {
    if (isOpen && (progress === ApprovalProgress.APPROVE_REJECTED || progress === ApprovalProgress.APPROVE_SUCCESS)) {
      closeApprovalModal();
    }
  }, [progress, isOpen]);
  if (!setting) return __jsx(StyledStatusLabel, null, __jsx(StyledMotion, {
    animate: {
      rotate: [0, 360]
    },
    transition: {
      duration: 1,
      ease: 'linear',
      repeat: Infinity
    }
  }, __jsx(IconLoading, null)));
  return __jsx(StyledStatusLabel, null, __jsx(StyledMotion, animationVariants, __jsx(setting.icon, null)), __jsx("span", {
    className: "approve-label"
  }, t(setting.i18n)));
};
export var StyledMotion = styled(motion.span).withConfig({
  displayName: "StyledMotion",
  componentId: "sc-8gk2u6-0"
})(["width:16px;height:16px;"]);
export var StyledStatusLabel = styled.div.withConfig({
  displayName: "StyledStatusLabel",
  componentId: "sc-8gk2u6-1"
})(["display:flex;align-items:center;padding-left:25px;background:linear-gradient(180deg,#30375b 0%,rgba(48,55,91,0) 100%);border-radius:6px 6px 0 0;height:63px;width:100%;border-bottom-left-radius:1rem;border-bottom-right-radius:1rem;img{width:16px;height:16px;}.approve-label{color:rgb(179,164,255);font-size:14px;font-weight:500;margin-left:14px;line-height:20px;display:inline-block;}"]);