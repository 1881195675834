import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RUNTIME_ENV } from "../../constants/env";
export var ApplicationModal;

(function (ApplicationModal) {
  ApplicationModal[ApplicationModal["ALLOWANCE_LIST"] = 0] = "ALLOWANCE_LIST";
  ApplicationModal[ApplicationModal["ALLOWANCE_TOKEN"] = 1] = "ALLOWANCE_TOKEN";
  ApplicationModal[ApplicationModal["APPROVAL_STATUS"] = 2] = "APPROVAL_STATUS";
  ApplicationModal[ApplicationModal["BUYBACK"] = 3] = "BUYBACK";
  ApplicationModal[ApplicationModal["CLAIMING"] = 4] = "CLAIMING";
  ApplicationModal[ApplicationModal["TRADE_ORDER"] = 5] = "TRADE_ORDER";
  ApplicationModal[ApplicationModal["LON_STAKING"] = 6] = "LON_STAKING";
  ApplicationModal[ApplicationModal["LON_REDEMPTION"] = 7] = "LON_REDEMPTION";
  ApplicationModal[ApplicationModal["MOBILE_SIDEBAR"] = 8] = "MOBILE_SIDEBAR";
  ApplicationModal[ApplicationModal["SPOT_SWAP_SETTINGS"] = 9] = "SPOT_SWAP_SETTINGS";
  ApplicationModal[ApplicationModal["WALLET_CONNECT"] = 10] = "WALLET_CONNECT";
  ApplicationModal[ApplicationModal["WALLET_INFO"] = 11] = "WALLET_INFO";
  ApplicationModal[ApplicationModal["LIMIT_ORDER_PREVIEW"] = 12] = "LIMIT_ORDER_PREVIEW";
  ApplicationModal[ApplicationModal["WETH_WRAP"] = 13] = "WETH_WRAP";
  ApplicationModal[ApplicationModal["WETH_PREVIEW"] = 14] = "WETH_PREVIEW";
  ApplicationModal[ApplicationModal["WETH_RESULT"] = 15] = "WETH_RESULT";
  ApplicationModal[ApplicationModal["TOOL"] = 16] = "TOOL";
  ApplicationModal[ApplicationModal["FEEINTRO"] = 17] = "FEEINTRO";
  ApplicationModal[ApplicationModal["SWAP_ORDER_PREVIEW"] = 18] = "SWAP_ORDER_PREVIEW";
  ApplicationModal[ApplicationModal["LON_REWARD"] = 19] = "LON_REWARD";
  ApplicationModal[ApplicationModal["PAY_WITH_TOKENS_INTRO"] = 20] = "PAY_WITH_TOKENS_INTRO";
  ApplicationModal[ApplicationModal["MINI_SET_PAY_MODE"] = 21] = "MINI_SET_PAY_MODE";
  ApplicationModal[ApplicationModal["LIMIT_ORDER_TRADE_FEE_INTRO"] = 22] = "LIMIT_ORDER_TRADE_FEE_INTRO";
  ApplicationModal[ApplicationModal["LO_ADD_CUSTOM_TOKEN"] = 23] = "LO_ADD_CUSTOM_TOKEN";
  ApplicationModal[ApplicationModal["PERMIT2_INTRO"] = 24] = "PERMIT2_INTRO";
  ApplicationModal[ApplicationModal["SWAP_PROTECTION_INTRO"] = 25] = "SWAP_PROTECTION_INTRO";
  ApplicationModal[ApplicationModal["SMART_ROUTING_DETAIL"] = 26] = "SMART_ROUTING_DETAIL";
  ApplicationModal[ApplicationModal["SPOT_SWAP_ONBOARDING"] = 27] = "SPOT_SWAP_ONBOARDING";
})(ApplicationModal || (ApplicationModal = {}));

var initialState = {
  blockNumber: {},
  chainId: null,
  openModal: [],
  modalState: {},
  refreshToken: 0,
  feeData: {}
};
var applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateChainId: (state, action) => {
      var chainId = action.payload.chainId;
      state.chainId = chainId;
    },
    updateBlockNumber: (state, action) => {
      var _action$payload = action.payload,
          chainId = _action$payload.chainId,
          blockNumber = _action$payload.blockNumber;

      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber;
      } else {
        state.blockNumber[chainId] = Math.max(state.blockNumber[chainId], blockNumber);
      }
    },
    updateFeeData: (state, action) => {
      var _action$payload2 = action.payload,
          chainId = _action$payload2.chainId,
          feeData = _action$payload2.feeData;
      if (!state.feeData) state.feeData = {};
      state.feeData[chainId] = feeData;
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload;
    },
    setModalState: (state, action) => {
      var _action$payload3 = action.payload,
          modal = _action$payload3.modal,
          modalState = _action$payload3.state;
      state.modalState[modal] = state.modalState[modal] || {};
      state.modalState[modal] = _objectSpread(_objectSpread({}, state.modalState[modal]), modalState);
    },
    updateRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    }
  }
});
var _applicationSlice$act = applicationSlice.actions,
    updateChainId = _applicationSlice$act.updateChainId,
    updateBlockNumber = _applicationSlice$act.updateBlockNumber,
    setOpenModal = _applicationSlice$act.setOpenModal,
    setModalState = _applicationSlice$act.setModalState,
    updateRefreshToken = _applicationSlice$act.updateRefreshToken,
    updateFeeData = _applicationSlice$act.updateFeeData;
export { updateChainId, updateBlockNumber, setOpenModal, setModalState, updateRefreshToken, updateFeeData };
export var applicationReducer = applicationSlice.reducer;
export var applicationPersistConfig = {
  key: "sub-application-".concat(RUNTIME_ENV),
  storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: ['openModal']
};
export default persistReducer(applicationPersistConfig, applicationReducer);